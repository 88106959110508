import type { UiNode } from "@ory/client";
import type { UiNodeInput } from "~/components/kratos/types";

export function notEnterpriseSSOProvider(node: UiNode) {
  const { attributes, group } = node as UiNodeInput;
  return (
    group !== "oidc" ||
    !(
      attributes.value.startsWith("okta") || attributes.value.startsWith("ping") || attributes.value.startsWith("microsoft-")
    )
  );
}

function isSocialSignInProvider(node: UiNode) {
  const { attributes, group } = node as UiNodeInput;
  return (
    group === "oidc" &&
    (attributes.value.startsWith("google") ||
      attributes.value.startsWith("github") ||
      attributes.value === "microsoft")
  );
}

export function filterOIDCProviders(
  enterpriseSSOProvider: { name: string; clientId: string } | null,
  socialSignInEnabled: boolean,
) {
  return (node: UiNode) => {
    if (notEnterpriseSSOProvider(node)) {
      if (socialSignInEnabled) {
        return true;
      }

      return !isSocialSignInProvider(node);
    }

    if (!enterpriseSSOProvider) {
      return false;
    }

    const { attributes, meta } = node as UiNodeInput;
    const providerId = `${enterpriseSSOProvider.name}-${enterpriseSSOProvider.clientId}`;

    if (attributes.value !== providerId) {
      return false;
    }

    // Kratos uses OIDC provider id as a label.
    // We need to rewrite `Link/Unlink okta-12345678` to `Link/Unlink okta`
    const newLabel = meta.label!;
    newLabel.text = newLabel.text.replace(
      providerId,
      enterpriseSSOProvider.name,
    );
    meta.label = newLabel;

    return true;
  };
}
